import $ from "jquery";

(function ($) {
    $.fn.validation = function (size) {
        var $form = this,
            $send = $form.find(':submit'),
            $close = $form.find('.icon-close');
        var validators = {
            contact: function (inp) {
                var email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                var tel = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
                return (inp.val().match(email) || inp.val().match(tel)) ? true : "Не верный телефон или e-mail";
            },
            files: function (inp) {
                if (inp.val() != ""){
                    var maxSize = 5;
                    var fileSize = Math.round((inp[0].files[0].size/1024/1024)*100)/100;
                    //var siz_this = Math.round((siz.files[0].size/1024/1024)*100)/100;
                    //console.log(this);
                    console.log(fileSize);
                    //console.log(Math.round((inp[0].files[0].size/1024/1024)*100)/100);
                    //console.log(fileSize - maxSize);
                    //console.log(inp[0].files[0].size);
                    //alert('Размер этого файла: ' + size + "MB");
                    /*if (fileSize < maxSize){
                        console.log('ОК. Меньше 5 мб');
                    } else{
                        console.log('ОШИБКА. Больше 5 мб');
                    }*/
                    return fileSize < maxSize ? true : "Максимальный размер файла — 5&nbsp;МБ";
                }
                else {
                    return "Добавьте фото";
                }
                //console.log(inp.val());
                return true;
            },
            required: function (inp) {
                return inp.val() != "" ? true : "Обязательное поле";
            }
        };

        var numValidatedFields = 0;

        function validAll() {
            $form.find('input[type="text"], .files label.nowrap input, .files label:first-of-type input').each(function () {
                if (!$(this).data('validate')) return;
                var inp = $(this);
                var close = inp.next('.icon-close');
                var validations = inp.data('validate').split(/\s*,\s*|\s+/);
                //console.log(validations);


                //var fileSize = inp.data('size');
                //var fileSize2 = inp[0].files[0].size;

                //console.log(inp);
                //console.log(fileSize);
                //console.log(fileSize2);

                numValidatedFields++;

                var validate = function () {
                    //console.log('validate process');
                    var errors = [],
                        i,
                        validation,
                        res;
                    //console.log(fileSize);
                    //console.log(val);

                    //console.log(fileSize);


                    for (i=0; i < validations.length; i++) {
                        validation = validations[i];
                        //console.log(validation);

                        res = validators[validation].call(this, inp);
                        //console.log(res);
                        if (res == false || typeof res === 'string') {
                            errors.push(res || "Error");
                        }

                    }
                    //console.log(validation);
                    //console.log(errors);

                    //var options = ['files', 'filesize'];

                    if (errors.length > 0) {
                        //console.log('есть ошибка');
                        if(validation == 'files'){
                            inp.parent().removeClass('valid').addClass('error');
                        }else{
                            inp.removeClass('valid').addClass('error');
                        }

                        if(!inp.next('small').length && !inp.parent().next('small').length){
                            if(validation == 'files'){
                                inp.parent().after('<small>'+errors[0]+'</small>')
                            }else{
                                inp.after('<small>'+errors[0]+'</small>')
                            }

                        } else {
                            if(validation == 'files'){
                                inp.parent().next('small').html(errors[0]);
                            }else{
                                inp.next('small').html(errors[0]);
                            }

                        }
                        $send.parent().prop('disabled', true);
                        //return false;
                        //event.preventDefault();
                    } else{
                        if(validation == 'files'){
                            inp.parent().removeClass('error').addClass('valid').next('small').remove();
                        }else{
                            inp.removeClass('error').addClass('valid').next('small').remove();
                        }

                        if ($form.find(".error").length) {
                            $send.parent().prop('disabled', true);
                        }else {
                            $send.parent().prop('disabled', false);
                        }
                    }



                };


                inp.change(function () {
                    //console.log('blur');
                    validate();
                    //size.call(this);
                });
                inp.keyup(function () {
                    //console.log('keyup');
                    if(inp.hasClass('error')){
                        validate();
                        size.call(this);
                    }
                });
                inp.blur(function () {
                    //console.log('blur');
                    validate();
                    size.call(this);
                });
                /*$form.on('change', function () {
                    //console.log('изменение формы');
                    validate();
                    size.call(this);
                });*/
                /*$send.on('click', function () {
                    //console.log('кнопка');
                    if (numValidatedFields != $form.find(".valid").length) {
                        validate();
                        $form.parent().addClass('animate');
                        $form.find('input.error').first().focus();
                        size.call(this);
                    }
                });*/


                if (numValidatedFields != $form.find(".valid").length) {

                    //console.log(numValidatedFields+'—'+$form.find(".valid").length);
                    validate();
                    //$form.parent().addClass('animate');
                    $form.find('input.error').first().focus();
                    //size.call(this);
                    //console.log('Доб. анимация');
                }

            });
        }


        $form.find('.files input').on('change', function () {
            //console.log('Кнопка Добавить файл');
            //console.log(this);
            setTimeout(function () {
                validAll();
                //size.call(this.prev());
            },10);
        });
        $close.on('click', function () {
            //console.log('Кнопка удалить');
            //console.log(this);
            setTimeout(function () {
                validAll();
                //size.call(this.prev());
            },20);
        });
        $send.on('click', function () {
            //console.log('кнопка');
            //if (numValidatedFields != $form.find(".valid").length) {
            validAll();
            if($form.find(".error").length > 0){
                $form.parent().addClass('animate');
            }
            //}
        });


        //return false;
        return this;
    };
    return false;
})(jQuery);