import $ from 'jquery'; //сжать только вручную. используя Хром
global.jQuery = $;
global.$ = $;
import Bricks from 'bricks.js';
require("./jquery.range.js");
//require("./validetta.js"); //можно сжать
require("./validation.js"); //валидация форм
/*require([
    './photoswipe.js',
    './photoswipe-ui-default.js'
], function( PhotoSwipe, PhotoSwipeUI_Default ) {

    //      var gallery = new PhotoSwipe( someElement, PhotoSwipeUI_Default ...
    //      gallery.init()
    //      ...

});*/
import PhotoSwipe from 'photoswipe'; // https://photoswipe.com/documentation/options.html
import PhotoSwipeUI_Default from './photoswipe-ui-default.js';

//require("https://yastatic.net/es5-shims/0.0.2/es5-shims.min.js"); // Блок «Поделиться» — для ie8
//require("https://yastatic.net/share2/share.js"); // Блок «Поделиться»

const sizes = [
    { columns: 1, gutter: 0 },
    { mq: '640px', columns: 2, gutter: 0 },
    { mq: '768px', columns: 3, gutter: 0 },
    { mq: '992px', columns: 3, gutter: 0 },
    { mq: '1340px', columns: 4, gutter: 0 }
];

//instance.on('pack',   () => console.log('ALL grid items packed.'))
    /*.on('update', () => console.log('NEW grid items packed.'))
    .on('resize', size => console.log('The grid has be re-packed to accommodate a new BREAKPOINT.'))*/;


/*document.addEventListener('DOMContentLoaded', event => {
});*/



$(document).ready(function() {
    //console.log(1234);
    //размеры окна
    let windowWidth,
        windowHeight,
        instance;

    //Сетка Mansory
    setTimeout(function () {
        gridMasonry();
    },10);

    //Тень меню при прокрутке
    $(window).scroll(function(){
        if ($(this).scrollTop() > 0) {
            $('header').addClass('fixed')
        }
        else{
            $('header').removeClass('fixed')
        }
    });

    //Открытие меню
    $('#menu-button').change(function () {
        if($(this).prop('checked') == true){
            $('#overlay').fadeIn(250);
            $('body').addClass('fixed');
        }
        else {
            $('#overlay').fadeOut(250);
            $('body').removeClass('fixed');
        }
    });
    $('#overlay').on('click', function () {
        $('#menu-button').prop('checked', false);
        $(this).fadeOut(250);
    });

    //Закрытие попапов по клику вне блока
    $(document).on('click', function(e){
        if ( $(e.target).closest('#more-drop').length || $(e.target).closest('.sorting').length) {
            // клик внутри элемента
            //console.log('inner');
            return;
        }
        // клик снаружи элемента
        if($('#wrap-drop').hasClass('open')){
            $('#wrap-drop').slideUp(200, function () {
                $('#more').removeClass('opened');
                $(this).removeClass('open');
            })
        }
        if($('.sorting').hasClass('open')){
            $('.sorting>div').slideUp(200, function () {
                $(this).parent().removeClass('open');
            })
        }
    });

    //Размеры окна
    function winSize() {
        windowWidth = $(window).outerWidth();
        windowHeight = $(window).outerHeight();
    }
    winSize();

    //Адаптивная кнопка "Еще"
    moreBut();
    $(window).resize(function() {
        moreBut();//пересчет пунктов окна
        winSize();//актуальные размеры окна
        setTimeout(function () {
            gridMasonry();
        },10);
        if(windowWidth>=768){
            togglePhoto(); // добавление превью фото для переключения
        }
    });
    $('nav').on('click', '#more-nav', function(e){
        e.preventDefault();
        $(this).toggleClass('opened');
        $('#wrap-drop').slideToggle(200, function () {
            $(this).toggleClass('open');
        })
    });

    let aside = $('aside.left.vertical');
    //Фильтр
    $(function() {
        //let period = '1699,1918';
        let period = $('#range').val();
        let clear = $('#opts .clear');
        let range = $('.range-slider');
        let y_input = $('#opts .years input.year');
        $('#filter h4').on('click', function () {
            if(windowWidth<1200 || !aside.length){
                $('.search-wrap').toggleClass('disable');
                $('#opts').slideToggle(200, function () {
                    $('#filter').toggleClass('open');
                })
            }
        });
        //Показ кнопки Сбросить
        $('#opts input').change(function () {
            if($('#opts input:checked').length || $('#range').val() != period){
                clear.addClass('active');
            } else {
                clear.removeClass('active').blur();
            }
        });
        //Кнопка Сбросить
        clear.on('click', function () {
            $('#opts .sections input').prop('checked', false);
            range.jRange('setValue', period);
            $(this).removeClass('active');
        });
        //Выбор раздела
        $('#opts .sections label').on('touchstart mouseover', function () {
            $(this).addClass('over');
        }).on('touchend mouseout', function (e) {
            $(this).removeClass('over');
        });
        //Выбор диапазона лет
        function yearsRange(w){
            range.jRange({
                from: 1699,
                to: 1918,
                width: w,
                isRange: true,
                onstatechange: function () {
                    //console.log(this.lowPointer);
                    let val = this.options.value;
                    //console.log(val);
                    let d_low = val.slice(0, 4);
                    //console.log(d_low);
                    let d_high = val.slice(5);
                    //console.log(d_high);
                    $('#low').val(d_low);
                    $('#high').val(d_high);
                    $('#range').focus();

                    if(val == period){
                        clear.removeClass('active').blur();
                    }else {
                        clear.addClass('active');
                    }
                },
                ondragend: function () {
                    $('#opts .pointer').add(y_input).removeClass('over');
                }
            });
        }
        //function wRange() {
            if (windowWidth < 1200 || !aside.length) {
                yearsRange(256);
            } else {
                yearsRange(218);
            }
        //}
        //wRange();
        /*$(window).resize(function () {
            range.next().remove();
            setTimeout(function () {
                wRange();
            },300);
        });*/
        y_input.change(function () {
            let d_val = $(this).val();
            let r_val = $(this).nextAll().filter("#high").val();
            let l_val = $(this).prevAll().filter("#low").val();
            //console.log(r_val);
            function activePointer(d) {
                $('.back-bar .'+ d).addClass('last-active').siblings().removeClass('last-active');
            }
            if(d_val > r_val){
                d_val = r_val;
                activePointer('low');
            }
            //console.log(l_val);
            if(d_val < l_val){
                d_val = l_val;
                activePointer('high');
            }
            if($(this).attr('id') == 'low'){
                range.jRange('setValue', d_val+','+r_val);
            }else {
                range.jRange('setValue', l_val+','+d_val);
            }
        }).focus(function () {
            $('#opts .pointer.'+$(this).attr('id')).addClass('over');
        }).blur(function () {
            $('#opts .pointer.'+$(this).attr('id')).removeClass('over');
        });
        $('#opts .years .pointer').on('mouseover mousedown touchstart', function () {
            $(this).addClass('over');
            if($(this).hasClass('low')){
                $('#low').addClass('over').siblings('#high').blur();
            }else {
                $('#high').addClass('over').siblings('#low').blur();
            }
        }).on('mouseout', function (e) {
            if(!e.which == 1){
                $(this).add(y_input).removeClass('over');
            }
        });
        //Отправка настроек фильтра
        $('#opts').submit(function (e) {
            e.preventDefault();
        });
    });


    //Поиск
    $('#search').on('click', function () {
        if(windowWidth<1200 || !aside.length){
            $(this).parent().removeClass('disable');
            $(this).find('input').focus();
            $('#opts').slideUp(200, function () {
                $('#filter').removeClass('open');
            })
        }
    });


    //Выделение формы по клику преимущств
    $('.item-plus, .button-send').on('click', function (e) {
        e.preventDefault();
        setTimeout(function () {
            $('html, body').animate({
                scrollTop:$("#form").offset().top-75
            },300,function () {
                $('.item-plus').on('mouseenter')
            });
            $('#form').find('input').first().focus();
        },10);
    }).hover(
        function(){
            $('#form').addClass('active animate');
        },
        function(){
            $('#form').removeClass('animate');
        });

    /*Загрузка файлов*/
    function addItem(text) {
        const maxItem = 3;
        let index = $("#form .files input").length;
        let preMax = maxItem - 1;
        //console.log(index);
        //console.log(preMax);
        if ( maxItem != 0 && index < maxItem /*&& $(elem).val().length > 0*/ || $('#form .files').children('.new').length == 0 && maxItem != 0 && index == preMax) {
            $('#form .files').append('<label class="round new"><span>Загрузить еще фото</span><input type="file" name="file[]" data-validate="files"><i class="icon-close tip" title="Удалить"></i></label>');
        }
        setTimeout(function () {
            $('#form form').validation();
        },15);

    }

    //Форма заявки на оценку
    $('#form .files input').val('');//Очистка поля файлов при загрузке страницы.
    $('#form').on('click', function () {
        $(this).addClass('active');
        $('.item-plus').off('mouseenter mouseleave');
    })/*.on('change', function() {
        $(this).validation(function() {
            gridMasonry();//перестройка сетки
        })
    })*/.on('change', '.files  input[type=file]', function() {
        //console.log($(this).val().replace(/.*\\/, ""));
        if($(this).val().length > 0){
            var filename = $(this).val().replace(/.*\\/, "");
            $(this).prev("span").text(filename);
            //$(this).remove();
            $(this).parent().removeClass('new').addClass('nowrap');
            //console.log(filename);
            addItem(this);
            setTimeout(function () {
                $('#form form').validation();
            },15);
            //console.log(this);
            /*var attr = $(this).attr('data-size');
            if (typeof attr !== typeof undefined && attr !== false) {
                console.log('Нет атрибута');
            }*/
            //$(this).data('size', Math.round((this.files[0].size/1024/1024)*100)/100);
            //$(this).attr('data-size', Math.round((this.files[0].size/1024/1024)*100)/100);
            //alert('Размер этого файла: ' + Math.round((this.files[0].size/1024/1024)*100)/100 + "MB");
            /*var ext = "не определилось";
            var parts = this.files[0].name.split('.');
            if (parts.length > 1) ext = parts.pop();
            alert('Тип файла: ' + ext);*/
        }
        else{
            $(this).parent().add().next('small').remove();
            $(this).prev("span").text('Выбрать фото');
            addItem(/*this*/);
            //console.log('Не выбран');
        }
        if($('#form .files').children('.new').length > 1){
            //$('#form .files .new').children('input').val('');
            $('#form .files .new').last().remove();
        }
        if($('#form .files').children('label').length <= 1) {
            $("#forms .files span").text('Добавить фото');
        }
    }).on('click','.files .icon-close', function(e){
        e.preventDefault();
        //console.log('удалить файл');
        var parent = $(this).parent();
        parent.next('small').remove();
        //$(this).parent('label').remove();
        setTimeout(function () {
            parent.remove();
            if($('#form .files').children('.new').length == 0){
                addItem(/*$(this).prev('input')*/);
                //$('#form .files label.new').removeClass('new');
            }
            if($('#form .files').children('label').length <= 1) {
                $("#form .files label span").text('Добавить фото');
            }
            //$('#form form').validation();
        },10);
        //$('.files label.nowrap').parent('label').remove();

        //$(this).prev('input').val('');
    });
    $('#form form').validation(function() {
        gridMasonry();//перестройка сетки
        //console.log('Validation');
    }).submit(function(e) {
        e.preventDefault();
        //console.log('Form is ready');
        if($(this).find('.error').length == 0){
            //var form = this;
            var $that = $(this),
                $fields = $(this).find('fieldset:not(.text), input'),
                $send = $(this).find('#send'),
                formData = new FormData($that.get(0));
            //console.log(form);
            //console.log(formData);
            $.ajax({
                type: "POST",
                url: "/php/ocenkaform.php",
                dataType: "json",
                contentType: false,
                processData: false,
                data: formData,
                beforeSend: function() {
                    //$('#loader').show();
                    //log('beforeSend');
                    $fields.prop("disabled", true);
                    //$that.find('.file').addClass('disabled');
                    $send.html('<span id="ld"><i></i><i></i><i></i><i></i></span>')/*.parent().next('.loading').fadeIn()*/;
                },
                complete: function() {
                    //$('#loader').hide();
                    //console.log('Complete');
                    //$fields.prop("disabled", false);
                    //$that.find('.file').removeClass('disabled');
                    $send.html('Отправить').parent()/*.next('.loading').fadeOut()*/;
                },
                /*error: function() {
                    console.log('error', arguments);
                },*/
                success: function(json){
                    //console.log('Success');
                    if(json){
                        // тут что-то делаем с полученным результатом
                        //console.log(json);
                        if (json.result === "success") {
                            // Если сообщение отправлено
                            $fields.add($send).prop('disabled', true);
                            $that.find('.submit').after('<fieldset class="msg success"><h3>Заявка отправлена!</h3><button id="resend" class="round" type="button">Отправить ещё одну</button></fieldset>');
                            $that.find('.msg').slideDown(250);
                            //$send.prop('disabled', true);
                            //alert("Сообщение отправлено!");
                        } else {
                            // Если произошла ошибка
                            $that.find('.submit').after('<fieldset class="msg error"><h3>Ошибка. Не удалось отправить</h3><button id="resend" class="round" type="reset">Попробывать ещё раз</button></fieldset>');
                            //alert("Ошибка. Не удалось отправить");
                        }

                    }
                }
            });
        }

        //e.preventDefault();
    }).on('click','#resend', function(e){
        e.preventDefault();
        $('#form').find('fieldset, input, #send').prop('disabled', false);
        $('#form .files .nowrap').remove();
        if($('#form .files').children('.new').length == 0){
            addItem(/*'#form .files  input[type=file]'*/);
            //$('#form .files label.new').removeClass('new');
        }
        $("#form .files .new").removeClass('new').children('span').text('Добавить фото');
        /*if($('#form .files').children('label').length <= 1) {
            $("#form .files span").text('Добавить фото');
        }*/
        $(this).parent().slideUp(250, function () {
            $(this).remove();
        });
        $('#resend').parent().slideUp(250, function () {
            $(this).remove();
        })
        //$(this).prev('input').val('');
    }).find('input').blur(function () {
        //instance.pack();
    });



    //tooltip
    if(windowWidth>=1200){
        let hovered;
    $(".tip").hover(function() {

        let $this = $(this),
            title = $this.attr('title');
        if (!$(this).next('.tooltip').length){
            $this.after('<div class="tooltip">'+title+'</div>');
        }
        $this.data('title', title).attr('title', '').removeAttr('title');
        hovered = setTimeout(function () {
                $this.next('.tooltip').fadeIn(150);
        },100);

    }, function() {
        clearTimeout(hovered);
        $(this).attr('title', $(this).data('title')).next('.tooltip').fadeOut(100);
    }).mousemove(function (e) {
            let mouseY = e.clientY,
                mouseX = e.clientX;
            //console.log(mouseY);

            let $tip = $(this).next('.tooltip'),
            tooltipHeight = $tip.height(),
            tooltipWidth = $tip.width();

            $tip.css({
                'left':mouseX,
                'top':mouseY+20
            });
            //reposition
            if (tooltipWidth + mouseX+ 20> windowWidth-17) {
                $tip.css({
                    'left':mouseX-tooltipWidth-20
                });
            }
            if (tooltipHeight + mouseY +20 > windowHeight) {
                $tip.css({
                    'top':mouseY-20-tooltipHeight
                });
            }
            //$this.next('.tooltip').detach();
        });
    }
    //Сортировка
    $('.sorting h4').on('click', function () {
        //if(windowWidth<640){
            $(this).next('div').slideToggle(200, function () {
                $(this).parent().toggleClass('open');
            })
        //}
    });
    $('.sorting a').on('click', function (e) {
        e.preventDefault();
        $(this).addClass('active').siblings().removeClass('active');
    });
    $('.sorting a i').on('click', function () {
        $(this).addClass('active').siblings().removeClass('active');
    });


    //Select кастомизация
    $('select').on('click', function () {
        $(this).toggleClass('active');
    });
    $('select').on('focusout', function(){
        $(this).removeClass('active');
    });

    //Добавление и удаление из избранного
    $('.fav-add').on('click', function () {
        let title = $(this).attr('title');
        let data = $(this).data('fav');
        $(this).toggleClass('active').attr('title',data).data('fav',title).children().toggleText();
        //$(this).toggleText();
    });

    //Переворачивание монеты по кнопке
    function turnPhoto() {
        $('#photos img').addClass('animate');
        setTimeout(function () {
            $('#photos img').toggleClass('hidden').removeClass('animate');
            //$('#thumbs img').toggleClass('hidden');
        },300);
    }
    $('#turn').on('click', function () {
        turnPhoto();
        $('#thumbs img').toggleClass('hidden');
    });
    //Копирование фото для превью
    function togglePhoto(){
        if(!$('#thumbs').length){
            $('#photos').clone().attr('id','thumbs').insertAfter($("#turn")).find('img').unwrap().removeAttr('title alt').removeClass('tip');
        }
    }
    if(windowWidth>=768){
        togglePhoto();
    }
    $('.photos-wrap').on('click', '#thumbs img.hidden', function(){
        $(this).removeClass('hidden').siblings().addClass('hidden');
        turnPhoto();
    });

    //Скрытие длинного текста на мобильных
    var h_desc = $('.product .desc');
    if(windowWidth<768 && h_desc.length){
        //console.log(h_desc);
        if(h_desc.height()>500){
            //console.log('big');
            h_desc.addClass('hidden').append('<div id="more">Подробнее<i class="icon-arrow"></i></div>');
        }
    }
    /*$('.product').on('click', '#more', function(){
        $(this).hide(150).parent().removeClass('hidden');
    });*/
    $('#more').on('click', function(){
        let btn = $(this),
            par = btn.parent();
        if(btn.hasClass('prav')){
            //console.log(h_desc);
            btn.toggleClass('open').find('span').toggleText('Подробнее','Скрыть');
            if(windowWidth>=1340){
                $('.form-wrap, .pluses-wrap').slideToggle();
            }
            else {
                $('.form-wrap, .pluses-wrap').slideDown();
                if(!par.hasClass('hidden')){
                    //console.log('no hidden');
                    $('html, body').animate({
                        scrollTop:par.offset().top-75
                    },300);
                }
            }
        }
        par.toggleClass('hidden');
    });


    //Галерея изображений
    $(function() {
        var $pswp = $('.pswp')[0];
        //var image = [];
        $('#photos').each( function() {
            var $pic     = $(this),
                getItems = function() {
                    var items = [];
                    $pic.find('a').each(function() {//
                        var $href   = $(this).attr('href'),
                            $size   = $(this).data('size').split('x'),
                            $width  = $size[0],
                            $height = $size[1];
                        var item = {
                            src : $href,
                            w   : $width,
                            h   : $height
                        };
                        items.push(item);
                    });
                    return items;
                };
            var items = getItems();
            //console.log(items);
            /*$.each(items, function(index, value) {
                image[index]     = new Image();
                image[index].src = value['src'];
            });*/
            $pic.on('click', 'a', function(event) {
                event.preventDefault();

                var $index = $(this).index('a')-1;
                var options = {
                    //index: $index,
                    index: 0,
                    bgOpacity: 0.7,
                    showHideOpacity: true,
                    closeOnScroll: false,
                    fullscreenEl: false,
                    shareEl: false
                };
                var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
                lightBox.init();
            });
        });
    });


    // Сетка Masonry
    function gridMasonry(){
        $('.masonry').each(function() {
            //console.log('masonry');
            let data = '.masonry[data-masonry="'+$(this).data('masonry')+'"]';
            //let instance;
            //console.log(data);
            if($(this).hasClass('cols-3')){
                instance = Bricks({
                    container: data,
                    packed: 'packed',
                    sizes: [
                        { columns: 1, gutter: 0 },
                        { mq: '640px', columns: 2, gutter: 0 },
                        { mq: '768px', columns: 3, gutter: 0 }
                    ],
                    position: true
                });
            }else {
                instance = Bricks({
                    container: data,
                    //packed: 'data-packed',
                    packed: 'packed',
                    sizes: sizes,
                    position: true
                });
            }
            //setTimeout(function () {
                instance
                //.resize(true)     // bind resize handler
                    .pack();          // pack initial items
            //},10);
        });
    }

    // Адаптивная кнопка "Еще"
    function moreBut() {
        //console.log('but');
        //setTimeout(function () {
        var w_width = $(window).outerWidth();
        if(w_width >= 640 && w_width < 1340){
            //console.log('mode more');
            let ul_w = $('nav .menu').width();
            //console.log(ul_w);
            //console.log($('nav li').width());

            var all_w = 0;

            // Сохранение исходных значений
            /*var all_li = [];
            $('nav .menu li').each(function(i,e) {
                all_li.push(e);
                //console.log(all_li);
            });*/

            $('nav .menu li').removeClass('def more');
            // Помечивание пунктов
            $('nav .menu li').each(function(i,e) {
                //console.log(all_w);

                /*let delta = ul_w - all_w;
                console.log(delta);*/

                //$(e).removeClass('def more');

                if(ul_w - all_w - 73 > $(e).width()){
                    all_w = all_w + $(e).width();
                    $(e).addClass('def');
                }
                else {
                    return false;
                }
            });
            // Перенос активного пункто в видимые. Пока скрыто
            /*if($('nav .menu li.def').last().nextAll().find('.active').length){
                console.log('active');
                var li_active = $('nav .menu a.active').parent();

            }*/

            // Скрытые пункты меню
            //$('nav .menu li').removeClass('more');
            var more_li = '';
            $('nav .menu li:not(.def)').each(function(i,e) {
                more_li = more_li + e.innerHTML;
                //console.log(more_li);
                $(e).addClass('more');
            });

            if(!$('#more-nav').length){
                //console.log('нет кнопки');
                $('nav ul.menu').append('<a href id="more-nav">Ещё<i class="icon-more"></i></a>');
            }
            //$('#wrap-drop').remove();
            if(!$('#wrap-drop').length){
                //console.log('нет кнопки');
                $('nav').append('<div id="wrap-drop"><ul id="more-drop">' + more_li + '</ul></div>');
            } else {
                $('#more-drop').html(more_li);
            }
        }
        else if(!$('#more').length){
            //console.log('нет кнопки');
            //$('nav ul.menu').append('<a href id="more">Ещё<i class="icon-more"></i></a>');
        }
        //},10);
    }


});


//Изменение текста
$.fn.extend({
    toggleText: function(a, b){
        if (typeof a == "undefined"){
            a = this.data('toggle1');
            b = this.data('toggle2');
        }
        return this.html(this.html() == b ? a : b);
    }
});

